<route>
{
  "meta": {
    "permission": [
      "subdomains.view_subdomain",
      "subdomains.add_subdomain"
    ]
  }
}
</route>

<template>
  <div class="mt-3">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="px-2" @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :loading="load"
            :title="`${$t('add', { value: $tc('subdomain', 2) })}`"
          ></i-toolbar>
          <v-card-text>
            <v-row>
              <!-- Empresa -->
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="company"
                  :name="$t('company')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    autocomplete="off"
                    outlined
                    name="company_id"
                    v-model="subdomain.company_id"
                    clearable
                    :items="companies"
                    class="secondary--text"
                    item-text="name"
                    item-value="pk"
                    key="department-input"
                    :error-messages="errors[0]"
                    :label="$t('company')"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>

              <!-- Nombre del esquema -->
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="schema_name"
                  :name="$t('name', 1)"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="subdomain.schema_name"
                    name="schema_name"
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('name', 1)"
                    class="secondary--text"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Aprobación del subdominio -->
              <v-col cols="12" md="6">
                <v-switch
                  class="v-switch mt-0"
                  v-model="subdomain.approved"
                  :label="$tc('approve')"
                  color="secondary"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {},
  data() {
    return {
      load: false,
      subdomain: {
        schema_name: '',
        approved: false,
        company_id: ''
      },
      companies: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    /**
     * getCompanies
     * Método que consulta y filtrar las empresas que no tengan un tenant
     * asociado y se los da al select empresa
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getCompanies() {
      const companies = await this.$api.company.list({})
      let results_companies = companies.data.results

      const subdomains = await this.$api.subDomain.list({})
      let results_subdomains = subdomains.data.results

      if (results_companies.length > 0 && results_subdomains.length > 0) {
        results_companies = results_companies.filter(
          (ar) => !results_subdomains.find((rm) => rm.company.pk === ar.pk)
        )
      }
      this.companies = results_companies
    },
    /**
     * submit
     * Método para enviar la información del frontend al api
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        await this.$api.subDomain.create({
          form: this.subdomain
        })
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('subdomain', 2)} ${this.$tc('created', 1)}`
        )
      } catch (error) {
        console.error(`Algo salió mal: ${error}`)
      } finally {
        this.load = false
        this.$router.push({ name: 'subdomain' })
      }
    }
  },
  mounted() {
    this.getCompanies()
  }
}
</script>
