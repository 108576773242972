import { render, staticRenderFns } from "./_pk.vue?vue&type=template&id=3b389cbc&"
import script from "./_pk.vue?vue&type=script&lang=js&"
export * from "./_pk.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./_pk.vue?vue&type=custom&index=0&blockType=route"
if (typeof block0 === 'function') block0(component)

export default component.exports